<template>
  <section class="app-banner">
    <div class="container">
      <div class="app-banner__inner">
        <div class="left">
          <div class="content">
            <h2 class="title fs32 pb30">{{title}}</h2>
            <p class="desc fs16 pb30">{{desc}}</p>
            <div class="app-banner__links">
              <a v-for="(link, index) in links" :key="index" :href="link.href" :class="{'qr-link': !!link.type}" @click="linkClick(link.type, $event)">
                <img :src="link.image" :alt="link.alt">
              </a>
            </div>
          </div>
        </div>
        <div class="right">
          <img class="app-banner__image" src="../../assets/img/images/app-notif.png" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['title', 'desc'],
  data() {
    return {
      links: [
        {
          image: require('../../assets/img/icons/app-store.svg'),
          href: 'https://appurl.io/YWuAaXGhSm',
          alt: 'App Store'
        },        {
          image: require('../../assets/img/icons/play-store.svg'),
          href: 'https://appurl.io/YWuAaXGhSm',
          alt: 'Play Store'
        },        {
          image: require('../../assets/img/images/qr-link.jpg'),
          type: 'qr',
          alt: 'QR code'
        },
      ]
    }
  },
  methods: {
    linkClick(type, event) {
      if(!!type) event.preventDefault()
    }
  }
}
</script>

<style lang="css" scoped>
.app-banner {
  padding-bottom: 100px;
}

.app-banner__inner {
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 24px rgba(192, 192, 192, 0.08);
}

.left {
  flex: 1;
}

.content {
  padding: 64px;
}

.right {
  flex: 1;
}

.app-banner__image {
  display: block;
  width: 100%;
}

.app-banner__links {
  display: flex;
  gap: 12px;
  align-items: center;
}

.app-banner__links img {
  display: block;
  width: 100%;
}

.qr-link {
  display: block;
  width: 44px;
  border-radius: 5px;
  border: 1.5px solid #18425D;
}

.qr-link img {
  padding: 5px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .content {
    padding: 46px 24px 40px 24px;
    max-width: 380px;
    text-align: center;
  }

  .app-banner__inner {
    flex-direction: column;
    gap: 0;
  }

  .app-banner__links {
    gap: 6px;
  }
}
</style>