<template>
  <div class="card">
    <div class="card__inner">
      <div class="card__content">
        <div class="card__image">
          <img
            class="card__image-img"
            src="@/assets/img/images/services.png"
            alt=""
          />
        </div>
        <router-link class="card__link mobile" :to="{name: 'Services'}"
          >Մանրամասն
          <img src="@/assets/img/utils/arrow-right.svg" alt="" />
        </router-link>
        <div class="grid">
          <div class="grid__item">
            <img
              src="@/assets/img/icons/mobile-services.svg"
              alt="mobile services"
              class="grid__item-icon"
            />
            <p class="grid__item-title">
              {{ $t('home.services.serviceNames.mobile') }}
            </p>
          </div>
          <div class="grid__item">
            <img
              src="@/assets/img/icons/fixed-phone.svg"
              alt="mobile services"
              class="grid__item-icon"
            />
            <p class="grid__item-title">
              {{ $t('home.services.serviceNames.fixed') }}
            </p>
          </div>
          <div class="grid__item">
            <img
              src="@/assets/img/icons/home-internet.svg"
              alt="mobile services"
              class="grid__item-icon"
            />
            <p class="grid__item-title">
              {{ $t('home.services.serviceNames.internet') }}
            </p>
          </div>
          <div class="grid__item">
            <img
              src="@/assets/img/icons/utility-payments.svg"
              alt="mobile services"
              class="grid__item-icon"
            />
            <p class="grid__item-title">
              {{ $t('home.services.serviceNames.utility') }}
            </p>
          </div>
          <div class="grid__item">
            <img
              src="@/assets/img/icons/gamebling.svg"
              alt="mobile services"
              class="grid__item-icon"
            />
            <p class="grid__item-title">
              {{ $t('home.services.serviceNames.betting') }}
            </p>
          </div>
          <div class="grid__item">
            <img
              src="@/assets/img/icons/other.svg"
              alt="mobile services"
              class="grid__item-icon"
            />
            <p class="grid__item-title">
              {{ $t('home.services.serviceNames.other') }}
            </p>
          </div>
        </div>
      </div>
      <router-link class="card__link desktop" to="/services">
        {{ $t('home.services.linkText') }}
        <img src="@/assets/img/utils/arrow-right.svg" alt="" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services Card'
};
</script>

<style scoped>
.card {
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 30px;
}
.card__inner {
  padding: 40px 50px 0;
  display: flex;
  flex-direction: column;
}
.card__content {
  display: flex;
  align-items: center;
  gap: 80px;
}
.card__image {
  width: 100%;
}
.card__image .card__image-img {
  max-width: 415px;
  width: 100%;
  height: 100%;
}
.card__link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right: 60px;
  margin-top: 40px;
  font-weight: bold;
}
.card__link.mobile {
  display: none;
  margin-top: 0;
  margin-right: 0;
  font-size: 14px;
}
.grid {
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
.grid__item {
  box-shadow: 0px 2px 20px rgba(154, 154, 154, 0.16);
  border-radius: 15px;
  max-width: 185px;
  height: 135px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid__item-icon {
  margin-bottom: 17px;
  max-width: 60px;
  max-height: 60px;
}
.grid__item-title {
  font-size: 15px;
  text-align: center;
  padding: 0 10px;
}
@media screen and (min-width: 320px) and (max-width: 550px) {
  .card {
    padding-bottom: 0;
  }
  .card__inner {
    padding: 24px;
    display: block;
  }
  .card__content {
    flex-direction: column-reverse;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid__item {
    width: 100%;
    height: 100px;
    margin: 0 auto;
    padding: 5px;
  }
  .grid__item-icon {
    width: 36px;
  }
  .grid__item-title {
    font-size: 13px;
    padding: 0 5px;
  }
  .grid__item-icon {
    margin-bottom: 10px;
  }
  .card__link.desktop {
    display: none;
  }
  .card__link.mobile {
    display: flex;
    margin-top: -30px;
  }
  .card__image {
    display: none;
  }
}
@media screen and (min-width: 551px) and (max-width: 900px) {
  .card__content {
    flex-direction: row;
  }
  .grid__item {
    height: 100px;
  }
  .grid__item-icon {
    width: 36px;
  }
  .grid__item-title {
    font-size: 13px;
  }
  .grid__item-icon {
    margin-bottom: 10px;
  }
  .card__link.desktop {
    display: flex;
  }
  .card__link.mobile {
    display: none;
  }
}
</style>
