<template>
  <main style="padding-top: 90px">
    <section class="services">
      <div class="container">
        <div class="services__inner">
          <div class="heading">
            <h1 class="title fs64 pb30 m0a">{{ $t('services.head.title') }}</h1>
            <p class="desc fs16 m0a">{{ $t('services.head.body') }}</p>
          </div>
          <ServicesAccordion />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ServicesAccordion from '@/components/TeamPayServices/ServicesAccordion.vue';
export default {
  name: 'TeamPay Services',
  components: { ServicesAccordion },
  metaInfo() {
    return {
      title: this.$t('meta.pages.services.title') + ' | TeamPay',
      description: this.$t('meta.pages.services.description')
    };
  }
};
</script>

<style scoped>
.services__inner {
  display: flex;
  flex-direction: column;
}
</style>
