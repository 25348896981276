<template>
  <div class="footer__contacts-box">
    <span class="footer__contacts-title">{{ $t('footer.contact.title') }}</span>
    <ul class="footer__contacts-list">
      <li class="footer__contacts-item">
        <a href="tel:+37410300003" class="footer__contacts-link">
          <img
            class="footer__contacts-icon"
            src="../../assets/img/icons/phone.svg"
            alt=""
          />
          +374 10 300 003
        </a>
      </li>
      <li class="footer__contacts-item">
        <a href="mailto:info@teampay.am" class="footer__contacts-link">
          <img
            class="footer__contacts-icon"
            src="../../assets/img/icons/email.svg"
            alt=""
          />
          info@teampay.am
        </a>
      </li>
      <li class="footer__contacts-item">
        <span to="/" class="footer__contacts-info">
          <img
            class="footer__contacts-icon"
            src="../../assets/img/icons/location.svg"
            alt=""
          />
          {{ $t('footer.contact.address') }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Footer Contacts'
};
</script>

<style scoped>
.footer__contacts-box {
  max-width: 210px;
  margin-left: 75px;
}
.footer__contacts-title {
  padding-bottom: 45px;
  font-weight: bold;
  display: block;
  width: 100%;
}
.footer__contacts-item {
  margin-bottom: 15px;
}
.footer__contacts-link {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}
.footer__contacts-info {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}
.footer__contacts-link:hover {
  text-decoration: underline;
}
.footer__contacts-icon {
  width: 15px;
}
@media screen and (min-width: 320px) and (max-width: 900px) {
  .footer__contacts-box {
    margin-left: 0;
    padding-top: 55px;
  }
  .footer__contacts-title {
    padding-bottom: 24px;
    font-size: 14px;
  }
}
@media screen and (min-width: 901px) and (max-width: 1024px) {
  .footer__contacts-box:not(:first-of-type) {
    margin-left: 25px;
  }
}
</style>
