<template>
  <section class="services">
    <div class="container">
      <div class="services__inner">
        <div class="services__info m0a">
          <h3 class="title fs32 pb30">{{ title }}</h3>
          <p class="desc fs16 pb30">
            {{ desc }}
          </p>
        </div>
        <ServicesCard />
      </div>
    </div>
  </section>
</template>

<script>
import ServicesCard from './ServicesCard.vue';
export default {
  name: 'TeamPay Services',
  components: {ServicesCard},
  props: {
    title: String,
    desc: String
  }
};
</script>

<style scoped>
.services {
  padding-top: 104px;
}
.services__inner {
  display: flex;
  flex-direction: column;
}
.services__info {
  text-align: center;
}
.services .title--xs {
  margin-right: 0;
}

@media screen and (max-width: 550px) {
  .services {
    padding-top: 40px;
  }
  .services .desc {
    padding-bottom: 25px;
  }
}
</style>
