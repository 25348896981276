<template>
  <div class="regulations">
    <div class="container">
      <div class="regulations__inner">
        <h2 class="title fs24 tl pb45">{{ title }}</h2>
        <div class="files">
          <ul class="files__list">
            <li class="files__list-item" v-for="doc in documents" :key="doc.id">
              <img
                class="files__list-icon"
                src="@/assets/img/utils/file-icon.svg"
                alt=""
              />
              <a :href="doc.href" class="files__list-text">{{ doc.title }}</a>
            </li>
          </ul>
        </div>
      </div>
      <p class="updated">{{ $t('updated') }} 28.02.2022</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Regulation',
  props: {
    title: String
  },
  data() {
    return {
      documents: [
        {
          id: '1',
          title: 'Լիցենզիա',
          href: '/docs/license.pdf'
        },
        {
          id: '2',
          title: 'Կանոնադրություն',
          href: '/docs/charter.pdf'
        }
      ]
    };
  }
};
</script>

<style></style>
