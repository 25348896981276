<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <Logo :src="logoLight" />
        <Navbar />
        <SearchEl />
        <LanguageSwitcher />
        <BurgerBtn :class="{open: isOpen}" @click="toggleBurger" />
      </div>
    </div>
    <MobileMenu
      :class="{open: isOpen}"
      @click="chooseLang($event)"
      @submitSearch="handleSearch"
    />
  </header>
</template>

<script>
import Navbar from './Navbar.vue';
import LanguageSwitcher from './LanguageSwitcher.vue';
import SearchEl from './SearchEl.vue';
import BurgerBtn from '../UI/BurgerBtn.vue';
import Logo from '../UI/Logo.vue';
import MobileMenu from './MobileMenu.vue';
export default {
  name: 'Header',
  components: {
    Navbar,
    LanguageSwitcher,
    SearchEl,
    BurgerBtn,
    Logo,
    MobileMenu
  },
  data() {
    return {
      isOpen: true,
      logo: require('../../assets/img/logo/logo_dark.png'),
      logoLight: require('../../assets/img/logo/logo-light.svg'),
      title: null
    };
  },
  methods: {
    closeMenu() {
      this.isOpen = !this.isOpen;
    },
    toggleBurger() {
      this.isOpen = !this.isOpen;
      if (this.isOpen === true) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'scroll';
      }
    },
    chooseLang(event) {
      if (event.target.classList.contains('router-link-active')) {
        this.isOpen = !this.isOpen;
        document.body.style.overflowY = 'scroll';
      }
    },
    resetBurger() {
      this.isOpen = false;
    },
    handleSearch() {
      this.isOpen = !this.isOpen;
      document.body.style.overflowY = 'scroll';
    }
  },
  mounted() {
    this.resetBurger();
  }
};
</script>

<style scoped>
.header {
  position: fixed;
  background-color: var(--teamBlue);
  left: 0;
  right: 0;
  top: 0;
  max-width: 100%;
  padding: 10px 0;
  z-index: 100;
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.open {
  transform: translateX(0);
}

@media screen and (max-width: 1024px) {
  .header__inner {
    align-items: flex-end;
  }
}
</style>
