<template>
  <nav class="nav" role="navigation" aria-label="Primary">
    <ul class="nav__menu">
      <li class="nav__menu-item" v-for="item in keys" :key="item.key">
        <router-link class="nav__menu-link" :to="$t(item.href)">
          {{ $t(item.key) }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      keys: [
        {
          key: 'nav.services.title',
          href: 'nav.services.href'
        },
        {
          key: 'nav.about_us.title',
          href: 'nav.about_us.href'
        },
        {
          key: 'nav.contact.title',
          href: 'nav.contact.href'
        }
      ]
    };
  }
};
</script>

<style scoped>
.nav__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
}
.nav__menu-item {
  margin-right: 26px;
  padding: 5px 0;
}
.nav__menu-link {
  font-size: 16px;
  position: relative;
}

.nav__menu-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 4px;
  background-color: var(--teamCyan);
  bottom: -21px;
  left: 0;
  transition: all 0.3s linear;
}

.nav__menu-link:hover::after {
  width: 100%;
}
.nav__menu-link.router-link-exact-active {
  position: relative;
}
.nav__menu-link.router-link-exact-active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--teamCyan);
  bottom: -21px;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .nav {
    display: none;
  }
}
</style>
