<template>
  <div class="card">
    <div class="card__inner">
      <div class="card__info">
        <h2 class="title fs32 pb30">{{ title }}</h2>
        <p class="desc fs16 pb30">
          {{ desc }}
        </p>
        <router-link class="card__link" :to="{name: 'Branches'}">{{
          linkText
        }}</router-link>
      </div>
      <div class="card__image">
        <img
          class="card__image-img"
          src="@/assets/img/images/hero-img.png"
          alt=""
        />
      </div>
    </div>
    <div class="card__features">
      <ul class="card__features-list">
        <li class="card__features-item">
          <img
            class="card__features-icon"
            src="@/assets/img/icons/checked.svg"
            alt=""
          />
          {{ $t('home.whatIs.features.utilities') }}
        </li>
        <li class="card__features-item">
          <img
            class="card__features-icon"
            src="@/assets/img/icons/card-icon.svg"
            alt=""
          />
          {{ $t('home.whatIs.features.notifications') }}
        </li>
        <li class="card__features-item">
          <img
            class="card__features-icon"
            src="@/assets/img/icons/folder-icon.svg"
            alt=""
          />
          {{ $t('home.whatIs.features.groups') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TeamPayLinkButton from '../UI/TeamPayLinkButton.vue';
export default {
  name: 'What is TeamPay',
  props: {
    title: String,
    desc: String,
    linkText: String
  },
  components: {TeamPayLinkButton}
};
</script>

<style scoped>
.card {
  background-color: #fff;
  box-shadow: 0px 4px 24px rgba(192, 192, 192, 0.08);
  border-radius: 20px;
  position: relative;
}
.card__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__info {
  display: flex;
  flex-direction: column;
  max-width: 443px;
  min-height: 440px;
  padding: 54px 0px 54px 74px;
}
.card__title {
  padding-bottom: 42px;
}
.card__desc {
  padding-bottom: 28px;
}
.card__link {
  font-weight: bold;
  margin-bottom: 76px;
}
.card__image {
  max-width: 100%;
}
.card__image .card__image-img {
  max-width: 626px;
  width: 100%;
  max-height: 490px;
  height: 100%;
}
.card__features {
  background-color: #fff;
  max-width: 220px;
  max-height: 200px;
  border-radius: 10px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card__features-list {
  padding: 24px;
}
.card__features-item {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.card__features-item:not(:last-child) {
  margin-bottom: 17px;
}
.card__features-icon {
  width: 40px;
  height: 40px;
  margin-right: 24px;
}
@media screen and (max-width: 1024px) {
  .card__inner {
    display: block;
  }
  .card__info {
    padding: 46px 24px 40px 24px;
    min-height: 100%;
  }
  .card__title {
    padding-bottom: 18px;
  }
  .card__desc {
    padding-bottom: 38px;
  }
  .card__link {
    margin-bottom: 0;
  }
  .card__image .card__image-img {
    max-width: 91%;
    display: block;
    margin-left: auto;
    border-radius: 10px;
  }
  .card__features {
    position: absolute;
    left: 0;
    transform: translate(0%, 90%);
    max-width: 160px;
  }
  .card__features-item {
    font-size: 12px;
  }
  .card__features-icon {
    width: 20px;
    margin-right: 12px;
  }
  .card__features-list {
    padding: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .card__inner {
    display: flex;
  }
  .card__info {
    width: 50%;
  }
  .card__image {
    width: 50%;
  }
  .card__features {
    transform: translate(-35%, -50%);
    left: 50%;
  }
}
@media screen and (min-width: 320px) and (max-width: 550px) {
  .card__inner {
    display: block;
  }
  .card__info {
    width: 100%;
    text-align: center;
    padding: 46px 24px 40px 24px;
  }
  .card__info .btn {
    margin: 0 auto;
  }
  .card__link {
    font-size: 14px;
  }
  .card__image {
    width: 91%;
    margin-left: auto;
  }
  .card__image .card__image-img {
    max-width: 100%;
    border-radius: 10px;
  }
  .card__features {
    position: absolute;
    left: 0;
    transform: translate(0%, 75%);
    max-width: 160px;
  }
}

@media screen and (max-width: 900px) {
  .card__info {
    max-width: 380px;
  }
  .card__image {
    width: 100%;
  }
}
</style>
