<template>
  <div class="not-found-wrapper">
    <div class="not-found">
      <h2 class="title--m">Error 404</h2>
      <span class="subtitle">Էջը չի գտնվել</span>
      <p class="desc">
        Կարծես թե էջը, որը փնտրում եք, գոյություն չունի կամ ջնջվել է
      </p>
      <div class="not-found__image">
        <img src="@/assets/img/images/not-found.jpg" alt="" />
      </div>
      <TeamPayLinkButton to="/" title="Գլխավոր էջ" />
    </div>
  </div>
</template>

<script>
import TeamPayLinkButton from '@/components/UI/TeamPayLinkButton.vue';
export default {
  name: 'Not Found Page',
  components: {
    TeamPayLinkButton
  },
  metaInfo() {
    return {
      title: this.$t('meta.pages.notFound.title') + ' | TeamPay'
    };
  }
};
</script>

<style>
.not-found-wrapper {
  width: 100%;
  height: 100%;
}
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 630px;
  background: #fff;
  padding: 36px;
  margin: 200px auto;
  background: white;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}
.not-found__image {
  padding-bottom: 40px;
}
.not-found .title--m {
  padding-bottom: 16px;
}
.not-found .title--xs {
  padding-bottom: 16px;
}
.not-found .desc {
  padding-bottom: 21px;
}
@media screen and (min-width: 320px) and (max-width: 550px) {
  .not-found {
    max-width: 90%;
    margin: 100px auto;
  }
}
</style>
