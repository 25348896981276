export default {
  hy: {
    name: 'ՀԱՅ',
    load: () => {
      return import('./translations/hy.json');
    }
  },
  en: {
    name: 'ENG',
    load: () => {
      return import('./translations/en.json');
    }
  },
  ru: {
    name: 'РУС',
    load: () => {
      return import('./translations/ru.json');
    }
  }
};
