<template>
  <div class="mobile-search" :searchEntry="searchEntry">
    <form class="search__form" @submit.prevent.enter="handleSearch">
      <label class="search__label" for="">
        <input
          class="search__input"
          type="text"
          :placeholder="$t('search.placeholder')"
          v-model="searchEntry"
        />
        <img
          class="search__icon"
          src="../../assets/img/utils/search-dark.svg"
          alt=""
          @click="handleSearch"
        />
      </label>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Mobile Search',
  data() {
    return {
      searchEntry: ''
    };
  },
  methods: {
    handleSearch() {
      this.$emit('submitSearch');
      this.$router.push({
        name: 'Search page',
        query: {q: this.searchEntry}
      });
      this.searchEntry = '';
    }
  }
};
</script>

<style scoped>
.search__form {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.search__label {
  display: flex;
  align-items: center;
  width: 625px;
  position: relative;
}
.search__input {
  width: 100%;
  height: 36px;
  border-radius: 10px;
  border: none;
  padding: 0 30px 0 15px;
  color: var(--teamBlue);
  outline: none;
}
.search__icon {
  position: absolute;
  right: 20px;
}
</style>
